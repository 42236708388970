import React, { useState, useEffect, useContext, Suspense, lazy } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { StoreContext } from "../../context/StoreContext.js";
import { types } from "../../context/StoreReducer";
import { useTranslation } from "react-i18next";
import Loader from "../loader/loader.jsx";
import { landings_config } from "../../config/app.config.js";

//? Lazy loading Components

const Hero = lazy(() => import("../hero/Hero"));
const Banner = lazy(() => import("./../banner/Banner"));
const Testimonials = lazy(() => import("./../testimonials/Testimonials"));
const Testimonialspt = lazy(() =>
  import("../testimonialspt/Testimonialspt.jsx")
);
const Landings = lazy(() => import("../landings/Landings.jsx"));
const Landingsmx = lazy(() => import("../landingsmx/Landingsmx.jsx"));
const Landingscl = lazy(() => import("../landingscl/Landingscl.jsx"));
const Features = lazy(() => import("../features/Features.jsx"));
const FaqComponent = lazy(() => import("../faq/index.js"));
const LandingsTop = lazy(() => import("../landingsTop/LandingsTop.jsx"));
const LandingsTopMx = lazy(() => import("../landingsTopMx/LandingsTopMx.jsx"));
const Landingspt = lazy(() => import("../landingspt/Landingspt.jsx"));

const HomeComponent = () => {
  const [store, dispatch] = useContext(StoreContext);
  const banner_visibility = useMediaQuery("(min-width: 640px)");
  const { i18n } = useTranslation();

  useEffect(() => {
    if (store.geo) {
      localStorage.setItem("country_name", store.geo.country_name);
      localStorage.setItem("currency_symbol", store.geo.currency_symbol);
      localStorage.setItem("entity_id", store.geo.entity);
      localStorage.setItem("seller_id", store.geo.seller);
      localStorage.setItem("country_code", store.geo.country);
      localStorage.setItem("country_id", store.geo.country_id);
      localStorage.setItem("header_whatsapp", store.geo.header_whatsapp);
      localStorage.setItem("locale", store.geo.locale);
    }
  }, [store.geo]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const discode = urlParams.get("voucher");

    localStorage.removeItem("voucher");

    if (localStorage.getItem("voucher2")) {
      localStorage.removeItem("voucher2");
    }

    if (discode) {
      localStorage.setItem("voucher", discode);
    }
    try {
      let url = window.location.href;
      let country_code = url.split("/")[3];
      if (country_code) {
        country_code = country_code.slice(0, 2);
      } else {
        country_code = localStorage.getItem("country_id");
      }
      fetch(
        `https://app.assist-365.com/api/geolocation?flag=true&country_code=${country_code}`
      )
        .then(response => response.json())
        .then(response => {
          dispatch({
            type: types.setGeo,
            payload: response,
          });
        })
        .catch(err => console.error(err));
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      <Suspense fallback={<Loader component={"Hero"} />}>
        <Hero />
      </Suspense>
      <div className='w-full mx-auto max-w-[358px] sm:max-w-[633px] xl:max-w-[1210px] px-4'>
        {banner_visibility && (
          <Suspense fallback={<Loader component={"Banner"} />}>
            {banner_visibility && store?.geo && <Banner />}
          </Suspense>
        )}

        {store?.geo?.country_id && (
          <Suspense fallback={<Loader component={"Testimonials"} />}>
            {store?.geo?.country_id !== 155 ? (
              <Testimonials />
            ) : (
              <Testimonialspt />
            )}
          </Suspense>
        )}

        {i18n.language !== "pt-BR" && (
          <Suspense fallback={<Loader component={"Landings"} />}>
            {store?.geo?.country_id == 164 && <Landings />}
            {store?.geo?.country_id == 52 && <Landingsmx />}
            {store?.geo?.country_id == 165 && <Landingscl />}
          </Suspense>
        )}
      </div>

      <div className='w-full'>
        <Suspense fallback={<Loader component={"Features"} />}>
          <Features />
        </Suspense>
      </div>

      <Suspense fallback={<Loader component={"Faq"} />}>
        <FaqComponent />
      </Suspense>

      {store?.geo?.country_id &&
        landings_config?.available_countries_ids.includes(
          store?.geo?.country_id
        ) && (
          <div className='w-full mx-auto max-w-[358px] sm:max-w-[633px] xl:max-w-[1210px] px-4 pb-16'>
            <Suspense fallback={<Loader component={"Landings"} />}>
              {store?.geo?.country_id == 164 && i18n.language !== "pt-BR" && (
                <LandingsTop />
              )}
              {store?.geo?.country_id == 155 && <Landingspt />}
              {store?.geo?.country_id == 52 && i18n.language !== "pt-BR" && (
                <LandingsTopMx />
              )}
            </Suspense>
          </div>
        )}
    </>
  );
};

export default HomeComponent;
